<template>
  <el-dialog
    :title="title"
    top="15vh"
    :visible.sync="show"
    width="425px"
    custom-class="custom-dialog"
    @open="openDialog"
    @close="closeDialog"
  >
    <div class="input-cell">
      <div v-for="(item,index) in list" :key="index" class="input-col">
        <el-input
          :class="{'ipt-active':current == index}"
          readonly
          :value="item.num"
          placeholder="0"
          @focus="changeCurrent(index)"
        ></el-input>
        <div class="input-label">{{ item.text }}</div>
      </div>
    </div>
    <div class="calculator">
      <div class="number-main">
        <div
          v-for="button in buttons"
          :key="button"
          class="button"
          @click="clickKey(button)"
        >
          {{ button }}
        </div>
      </div>
      <div class="buttons">
        <div class="button" @click="clear">重输</div>
        <div class="button button-submit" @click="confirm">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
let allowKey = {
  "1": true, "2": true, "3": true, "4": true, "5": true,
  "6": true, "7": true, "8": true, "9": true, "0": true,
  "Backspace": true, ".": true,
}
export default {
  data() {
    return {
      show: false,
      title: "",
      list: [],
      current: 0,

      inputA: "",
      inputB: "",
      inputC: "",
      display: "",
      buttons: ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0",'X'],
      activeInput: "A", // 默认选中第一个输入框

      isCover: true
    };
  },
  mounted() {
    
  },

  methods: {
    changeCurrent(index) {
      this.current = index;
      this.isCover = true
    },
    open(title,list) {
      this.title = title;
      this.current = 0;
      this.isCover = true
      this.list = list.map(item=>{
        item.num = parseFloat(item.num);
        item.num = item.num.toString();
        return item;
      })
      this.show = true;
    },
    clickKey(key) {
      let val = this.list[this.current].num;
      val = val.toString();
      if(key == 'X' || key == 'Backspace') {
        val = val.slice(0,-1);
      } else {
        if(this.isCover){
          val = key
          this.isCover = false
        }else {
          
          const pattern = /^\d+\.\d+$/;
          if (pattern.test(val) && val.toString().split(".")[1].length>=2) {
            return
          }
          val += key
          
        }
        
      }
      this.changeNum(val);
    },
    changeNum(val) {
      if(this.list[this.current].func){
        this.list[this.current].func.apply(this,[val,this.current]);
      }
      this.$set(this.list[this.current],'num',val);
    },
    handleKey(even) {
      if(allowKey[even.key]) {
        this.clickKey(even.key);
      }
      if(even.key == 'Enter' && this.current==this.list.length-1){
        this.confirm()
      } else if(even.key == 'Enter' && this.current<this.list.length-1){
        this.current += 1
        this.isCover = true
      }
    },
    clear(){
      this.list[this.current].num = "";
      this.changeNum('');
    },
    confirm(){
      this.$emit("confirm",this.list);
      this.show = false;
      this.current = 0;
    },
    openDialog(){
      window.addEventListener("keyup", this.handleKey);
    },
    closeDialog(){
      window.removeEventListener('keyup',this.handleKey);
    },
    
  },
};
</script>

<style lang="scss" scoped>
.calculator {
  display: flex;
  .buttons {
    width: 88px;
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 88px;
      height: 124px;
      border-radius: 4px;
      background: #ffffff;
      box-sizing: border-box;
      border: 0.8px solid #e3e3e3;
      font-family: DINOT;
      font-size: 24px;
      font-weight: bold;
      line-height: 60px;
      text-align: center;
      letter-spacing: 0px;
      margin: 3px 0;
      cursor: pointer;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    }

    .button-submit {
      background-color: #1661f7;
      color: #ffffff;
    }
  }
}
.number-main {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 2px;
    width: 88px;
    height: 60px;
    border-radius: 4px;
    background: #ffffff;
    box-sizing: border-box;
    border: 0.8px solid #e3e3e3;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    font-family: DINOT;
    font-size: 24px;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0px;
  }
}
.input-cell {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-left: -5px;
  margin-right: -5px;
  .input-col {
    flex: 1;
    padding: 0 5px;
    text-align: center;
  }
  .input-label {
    margin-top: 10px;
  }
  ::v-deep .el-input {
    width: 100%;
  }
  ::v-deep .el-input__inner {
    height: 60px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    background: #f3f3f3;
    box-sizing: border-box;
    border: 0.8px solid #e3e3e3;
    color: #000000;
  }
  
  ::v-deep .ipt-active .el-input__inner{
    background: #ffffff !important;
  }
    
  
}
.form-foot {
  display: flex;
  align-items: center;
  padding: 20px 0;
  padding-left: 140px;

  .btn {
    width: 140px;
    height: 42px;
    margin: 0 15px;
    border-radius: 4px;
    font-size: 16px;
  }

  .el-button--primary {
    box-shadow: 0px 3px 8px 0px rgba(22, 97, 247, 0.3);
  }

  .el-button--default {
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 3px 8px 0px rgba(237, 237, 237, 0.5);
  }
}

::v-deep .custom-dialog {
  border-radius: 5px;
  overflow: hidden;
}
::v-deep .custom-dialog .el-dialog__header {
  background-color: #f8f9fa;
  font-size: 16px;
  margin-bottom: 0;
  height: 54px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 0 20px 0 30px;
  font-weight: 500;
  border-radius: 5px;
}

::v-deep .custom-dialog .el-dialog__body {
  padding: 20px 29px;
  background-color: #f2f3f5;
}
</style>
