<template>
  <div class="countdown">
    <span class="n">{{ hours }}</span>
	<span class="dot">:</span>
	<span class="n">{{ minutes }}</span>
	<span class="dot">:</span>
	<span class="n">{{ seconds }}</span>
    <div v-if="isFinished" class="finished-message">{{ endTxt }}</div>
  </div>
</template>

<script>
export default {
  props: {
    endTime: {
      type: Number,
      required: true
    },
    endTxt: {
      type: String,
      default: '倒计时结束！',
    }
  },
  data() {
    return {
      hours: '00',
      minutes: '00',
      seconds: '00',
      interval: null,
      isFinished: false
    };
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    this.stopCountdown();
  },
  methods: {
    startCountdown() {
      this.updateCountdown();
      this.interval = setInterval(this.updateCountdown, 1000);
    },
    updateCountdown() {
      const now = Date.now();
      const distance = this.endTime - now;

      if (distance < 0) {
        this.stopCountdown();
        this.hours = '00';
        this.minutes = '00';
        this.seconds = '00';
        this.isFinished = true;
        this.$emit('finished');
        return;
      }

      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.hours = hours.toString().padStart(2, '0');
      this.minutes = minutes.toString().padStart(2, '0');
      this.seconds = seconds.toString().padStart(2, '0');
    },
    stopCountdown() {
      clearInterval(this.interval);
    }
  }
};
</script>

<style scoped>
.countdown {
  font-size: 24px;
  font-weight: bold;
}
.countdown .n {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: bold;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	background: #FFFFFF;
	box-sizing: border-box;
	color: #222;
	border: 0.8px solid #E3E3E3;

}
.countdown .dot{
	padding: 0 5px;
}
.finished-message {
  color: red;
  margin-top: 10px;
}
</style>
