<template>
  <el-dialog title="单次:洗剪吹(680)" top="15vh" :visible.sync="dialogVisible" width="425px" custom-class="custom-dialog">
    <div class="input-cell">
      <div class="input-col">
        <el-input readonly v-model="inputA" placeholder="0" @focus="setActiveInput('A')"></el-input>
		<div class="input-label">次数</div>
      </div>
      <div class="input-col">
        <el-input readonly v-model="inputB" placeholder="0" @focus="setActiveInput('B')"></el-input>
		<div class="input-label">折扣</div>
      </div>
      <div class="input-col">
        <el-input readonly v-model="inputC" placeholder="0" @focus="setActiveInput('C')"></el-input>
		<div class="input-label">金额</div>
      </div>
    </div>
    <div class="calculator">
      <div class="number-main">
		  <div v-for="button in buttons" :key="button" class="button" @click="input(button)">
		    {{ button }}
		  </div>
		  <div class="button" @click="deleteLastChar">
		    X
		  </div>
	  </div>
      <div class="buttons">
        <div class="button" @click="clearInput">重输</div>
        <div class="button button-submit" @click="calculate">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      inputA: "",
      inputB: "",
      inputC: "",
      dialogVisible: false,
      display: '',
      buttons: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0',],
      activeInput: 'A' // 默认选中第一个输入框
    };
  },
  mounted(){
    window.addEventListener('keyup',this.handleKey)
  },
  methods: {
    handleKey(even){
      if(even.key=='Backspace' || even.key=='.' || this.buttons.indexOf(even.key) != -1){
        this.input(even.key)
      }
    },
    setActiveInput(input) {
      this.activeInput = input;
      this.display = this['input' + input];
    },
    input(button) {
      if (button === 'Backspace') {
        // this.display += '*';
        this.display = this.display.slice(0,-1);
      } else {
        this.display += button;
      }
      this['input' + this.activeInput] = this.display;
    },
    calculate() {
      try {
        this.display = eval(this.display).toString();
        this['input' + this.activeInput] = this.display;
      } catch (e) {
        this.display = 'Error';
      }
    },
	deleteLastChar() {
	  this.display = this.display.slice(0, -1);
	  this['input' + this.activeInput] = this.display;
	},
    clearInput() {
      this.display = '';
      this['input' + this.activeInput] = '';
    }
  },
};
</script>

<style lang="scss" scoped>
	.calculator{
		display: flex;
		.buttons{
			width: 88px;
			.button{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 88px;
				height: 124px;
				border-radius: 4px;
				background: #FFFFFF;
				box-sizing: border-box;
				border: 0.8px solid #E3E3E3;
				font-family: DINOT;
				font-size: 24px;
				font-weight: bold;
				line-height: 60px;
				text-align: center;
				letter-spacing: 0px;
				margin: 3px 0;
				cursor: pointer;
				box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
			}
			
			.button-submit{
				background-color: #1661F7;
				color: #FFFFFF;
			}
		}
	}
	.number-main{
		display: flex;
		flex-wrap: wrap;
		flex: 1;
		.button{
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin: 2px;
			width: 88px;
			height: 60px;
			border-radius: 4px;
			background: #FFFFFF;
			box-sizing: border-box;
			border: 0.8px solid #E3E3E3;
			box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
			font-family: DINOT;
			font-size: 24px;
			font-weight: bold;
			line-height: 60px;
			text-align: center;
			letter-spacing: 0px;
		}
		
	}
	.input-cell{
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-bottom: 20px;
		margin-left: -5px;
		margin-right: -5px;
		.input-col{
			width: 128px;
			padding: 0 5px;
			text-align: center;
		}
		.input-label{
			margin-top: 10px;
		}
		::v-deep .el-input{
			width: 100%;
		}
		::v-deep .el-input__inner{
			height: 60px;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			border-radius: 4px;
			background: #FFFFFF;
			box-sizing: border-box;
			border: 0.8px solid #E3E3E3;
		}
	}
	.form-foot {
		display: flex;
		align-items: center;
		padding: 20px 0;
		padding-left: 140px;

		.btn {
			width: 140px;
			height: 42px;
			margin: 0 15px;
			border-radius: 4px;
			font-size: 16px;
		}

		.el-button--primary {
			box-shadow: 0px 3px 8px 0px rgba(22, 97, 247, 0.3);
		}

		.el-button--default {
			border-radius: 4px;
			background: #FFFFFF;
			border: 1px solid #E7E7E7;
			box-shadow: 0px 3px 8px 0px rgba(237, 237, 237, 0.5);
		}
	}

	::v-deep .custom-dialog{
		border-radius: 5px;
		overflow: hidden;
	}
	::v-deep .custom-dialog .el-dialog__header {
		background-color: #F8F9FA;
		font-size: 16px;
		margin-bottom: 0;
		height: 54px;
		display: flex;
		align-items: center;
		color: #000000;
		padding: 0 20px 0 30px;
		font-weight: 500;
		border-radius: 5px;
	}
	
	::v-deep .custom-dialog .el-dialog__body {
		padding: 20px 29px;
		background-color: #f2f3f5;
	}
</style>