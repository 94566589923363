<template>
	<el-dialog title="付款码" top="10vh" :visible.sync="dialogVisible" width="425px" custom-class="custom-dialog">
		<div class="payment">
			<div class="payment-code">
				<div class="title">请顾客用手机扫此二维码付款</div>
				<div class="deadline-time">
					<countdown :endTime="deadline" @finished="onCountdownFinished" />
				</div>
				<!-- <div class="vcode">
					<img src="@/assets/wx.png" alt="" />
				</div> -->
        <vueQr :logoSrc="imageUrl" :text="qcode" :size="160"></vueQr>
				<div class="title">支付金额:398 元</div>
			</div>
			<div class="and-label">或</div>
			<div class="pay-input">
				<el-input placeholder="请输入内容" v-model="nr">
					<template slot="append">
						<i class="el-icon-success"></i>
					</template>
				</el-input>
			</div>
			<div class="form-foot">
				<el-button class="btn" type="danger" @click="dialogVisible =false">取消支付</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Countdown from './Countdown.vue' 
  import {cardOrderPrePay} from '@/api'
  import vueQr from 'vue-qr'
	export default {
    props: {
      orderId: {
        type: String,
        default: ()=>{
          return ''
        }
      }
    },
		components: {
		    Countdown,vueQr
		  },
		data() {
			return {
				nr:'',
        imageUrl:require('@/assets/logo.png'),
        qcode: '',
				dialogVisible: false,
				// deadline: Date.now() + (new Date().setHours(12, 59, 59) - Date.now()),
				deadline: new Date().getTime() + 60 * 60 * 1000,
			};
		},
    created(){
      // this.getCode();
    },
		methods: {
			onCountdownFinished() {
			  console.log('倒计时结束！');
			  // 你可以在这里添加其他需要在倒计时结束时执行的逻辑
			},
      async getCode(){
        let params = {
          id: this.props.orderId
        }
        let {result} = await cardOrderPrePay(params);
        this.qcode = result.payinfo
      }

		},
	};
</script>
<style lang="scss" scoped>
	.pay-input ::v-deep .el-input__inner{
		height: 50px;
		border-radius: 4px;
		background: #FFFFFF;
		box-sizing: border-box;
		border: 0.8px solid #E3E3E3;
	}
	.pay-input ::v-deep .el-input-group__append{
		width: 76px;
		height: 50px;
		padding: 0 26px;
		border-radius: 0 4px 4px 0;
		background: #E9F1FF;
		box-sizing: border-box;
		border: 0.8px solid #E3E3E3;
	}
	.pay-input .el-icon-success{
		font-size: 22px;
		color: #1661F7;
	}
	.payment-code {
		text-align: center;

		.deadline-time {
			margin-top: 20px;
		}

		.vcode {
			margin: 24px auto;
			width: 160px;
			height: 160px;
			font-size: 0;

			img {
				width: 160px;
				height: 160px;
			}
		}

		.title {
			color: #2D3748;
			font-weight: 500;
			font-size: 16px;
		}
	}

	.and-label {
		text-align: center;
		padding: 10px 0;
		color: #999;
	}

	.form-foot {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;

		.btn {
			width: 140px;
			height: 42px;
			margin: 0 15px;
			border-radius: 4px;
			font-size: 16px;
		}

		.el-button--primary {
			box-shadow: 0px 3px 8px 0px rgba(22, 97, 247, 0.3);
		}

		.el-button--default {
			border-radius: 4px;
			background: #FFFFFF;
			border: 1px solid #E7E7E7;
			box-shadow: 0px 3px 8px 0px rgba(237, 237, 237, 0.5);
		}
	}

	::v-deep .custom-dialog {
		border-radius: 5px;
		overflow: hidden;
	}

	::v-deep .custom-dialog .el-dialog__header {
		background-color: #F8F9FA;
		font-size: 16px;
		margin-bottom: 0;
		height: 54px;
		display: flex;
		align-items: center;
		color: #000000;
		padding: 0 20px 0 30px;
		font-weight: 500;
		border-radius: 5px;
	}

	::v-deep .custom-dialog .el-dialog__body {
		padding: 20px 29px;
		background-color: #f2f3f5;
	}
</style>