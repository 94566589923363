import { queryStaffList, queryGoodsList, queryDict,getCards } from "@/api"

const state = {
  staffList: [],
  goodsList: [],
  payMethodList: [],
  recSourceList: [],
  cardTypeList: [],
  couponSourceList: [],
}

const mutations = {
  setStaffList(state, list) {
    state.staffList = list;
  },
  setGoodsList(state, list) {
    state.goodsList = list;
  },
  setPayMethodList(state, list) {
    state.payMethodList = list;
  },
  setRecSourceList(state, list) {
    state.recSourceList = list;
  },
  setCardList(state,list){
    state.cardTypeList = list
  },
  setCouponSourceList(state,list){
    state.couponSourceList = list
  },
  setModuleList(state,list){
    state.moduleList = list;
  }
}

const actions = {
  async queryRecSourceList({commit,state},refresh = false) {
    if(!refresh && state.recSourceList.length){
      return state.recSourceList;
    }
    try{
      let { result } = await queryDict('recommend_source');
      commit('setRecSourceList',result);
      return result;
    }catch(err){
      return null;
    }
  },
  async getCouponSourceList({commit,state},refresh = false){
    if(!refresh && state.couponSourceList.length){
      return state.couponSourceList;
    }
    try{
      let { result } = await queryDict('coupon_source');
      commit('setCouponSourceList',result);
      return result;
    }catch(err){
      return null;
    }
  },
  async queryPayMethodList({commit,state},refresh = false) {
    if(!refresh && state.payMethodList.length){
      return state.payMethodList;
    }
    try{
      let { result } = await queryDict('pay_method');
      commit('setPayMethodList',result);
      return result;
    }catch(err){
      return null;
    }
  },
  async queryGoodsList({commit,state},refresh = false) {
    if(!refresh && state.goodsList.length){
      return state.goodsList;
    }
    try{
      let { result } = await queryGoodsList({
        pageNo: 1, pageSize: 99,
      });
      commit('setGoodsList',result.records);
      return result.records;
    }catch(err){
      return null;
    }
  },
  async queryStaffList({commit,state},refresh = false) {
    if(!refresh && state.staffList.length){
      return state.staffList;
    }
    try{
      let { result } = await queryStaffList({
        pageNo: 1, pageSize: 99,
      });
      commit('setStaffList',result.records);
      return result.records;
    }catch(err){
      return null;
    }
  },
  async getCards({commit,state},refresh = false) {
    if(!refresh && state.staffList.length){
      return state.staffList;
    }
    try{
      let { result } = await getCards({
        pageNo: 1, pageSize: 99,isOnSelling:true
      });
      commit('setCardList',result.records);
      return result.records;
    }catch(err){
      return null;
    }
  },
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
