<template>
  <div>
    <el-dialog title="业绩分配" top="10vh" :visible.sync="dialogVisible" width="800px" custom-class="custom-dialog">
		<div class="service-main flex">
			<div class="service-aside" v-loading="page.loading">
				<div class="service-hd">服务人员</div>
				<!-- <div class="check-list">
					<el-checkbox-group v-model="peopleCurrent" @change="handleChecked">
					  <el-checkbox v-for="item in peoples" :label="item" :key="item">{{item}}</el-checkbox>
					</el-checkbox-group>
				</div> -->
        <div class="check-list" v-infinite-scroll="getStaffList">
            <div v-for="item in staffList" :key="item.id">
              <el-checkbox
                :value="map[item.id] != undefined"
                @change="clickStaff(item)"
              >
                {{ item.realname }}{{item.workNo?"["+item.workNo+"]":''}}
              </el-checkbox>
            </div>
          </div>
			</div>
			<div class="service-panel ">
				<div class="service-list">
					<div class="service-card" v-for="(item,index) in Data" :key="index">
						<div class="service-card-hd">
							<div class="tt-left">
								<span class="iconfont icon-user"></span><span class="tt">{{item.employeeName}}</span>
							</div>
							<!-- <div class="tt-link">
								<div class="link-item text-primary">
									<span class="iconfont icon-add"></span><span class="tt" @click="addServe(index)">加服务</span>
								</div>
								<div class="link-item text-danger">
									<span class="iconfont icon-yichu"></span><span class="tt" @click="remove(index)">移除</span>
								</div>
							</div> -->
              <div class="service-row" >
                <div class="col">
									<span class="text-primary">业绩比例</span><span class="text-danger row-number">{{Number(item.percent) || 0}}</span>
                  <span class="iconfont icon-edit text-danger pointer" @click="editNum(index)"></span>
								</div>
								<div class="col">
									<span class="text-primary">业绩</span><span class="text-danger row-number">{{Number(item.amount) || 0}}</span>
                  <span class="iconfont icon-edit text-danger pointer" @click="editNum(index)"></span>
								</div>
								<div class="col">
									<span class="text-primary">提成</span><span class="text-danger row-number">{{Number(item.percentage) || 0}}</span>
                  <span class="iconfont icon-edit text-danger pointer" @click="editNum(index)"></span>
								</div>
								<div class="btn-del">
									<span class="iconfont icon-del pointer" @click="delServe(index,item)"></span>
								</div>
							</div>
						</div>
						<div class="service-card-bd">
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-foot">
			<el-button class="btn" @click="dialogVisible = false">返回</el-button>
      <el-button class="btn" type="primary" @click="submit">
        确认
      </el-button>
		</div>
	  </el-dialog>
    <addServeDialog ref="addServeDialog" @selectServe="selectServe"></addServeDialog>
    <keyboardDialog ref="keyboardDialog" @confirm="changeNum"></keyboardDialog>
  </div>
	
  
</template>

<script>
  import addServeDialog from '@/components/add-serve-dialog.vue'
  import keyboardDialog from "@/components/keyboard-dialog.vue";
  import {queryStaffList,saveTableSubmitCardPerf} from '@/api'
	export default {
    components:{
      addServeDialog,
      keyboardDialog
    },
		data() {
			return {
        addIndex: 0,
				dialogVisible: false,
        map: {},
        detail: {},
        curStaff: 0,
        page: {
          current: 1,
          size: 16,
          loading: false,
          end: false,
        },
				peopleCurrent:[],
				staffList: [],
				Data:[
					// {
					// 	name:'俞佩',
					// 	lists:[
					// 		{
					// 			title:'销售',
					// 			achie:'98',
					// 			commission:'100'
					// 		},{
					// 			title:'手工服务',
					// 			achie:'0',
					// 			commission:'0'
					// 		}
					// 	]
					// },{
					// 	name:'俞佩',
					// 	lists:[
					// 		{
					// 			title:'销售',
					// 			achie:'98',
					// 			commission:'100'
					// 		},{
					// 			title:'手工服务',
					// 			achie:'0',
					// 			commission:'0'
					// 		}
					// 	]
					// },{
					// 	name:'俞佩',
					// 	lists:[
					// 		{
					// 			title:'销售',
					// 			achie:'98',
					// 			commission:'100'
					// 		},{
					// 			title:'手工服务',
					// 			achie:'0',
					// 			commission:'0'
					// 		}
					// 	]
					// }
				],
        cardPrice: 0
			};
		},
		methods: {

      open(detail) {
        let info = JSON.parse(JSON.stringify(detail));
        this.Data = [];
        this.detail = {};
        this.detail = info;
        this.cardPrice = info.orderPrice;
        this.dialogVisible = true;
        if(!this.staffList.length){
          this.getStaffList();
        }
        if(info.card.perfTableList){
          info.card.perfTableList.forEach(item=>{
            this.Data.push({
              employeeId: item.employeeId,
              employeeName: item.employeeName,
              percent: item.percent,
              amount: item.amount,
              percentage:item.percentage,
              cardPrice: info.orderPrice
            })
          })
        }
        
        // this.Data = info.card.perfTableList || [];
        let staffMap = {};
        for(let i = 0; i < this.Data.length; i++) {
          staffMap[this.Data[i].employeeId] = i;
        }
        this.map = staffMap;

      },
      async getStaffList() {
        if (this.page.loading || this.page.end) return;
        try {
          this.page.loading = true;
          let params = {
            pageNo: this.page.current,
            pageSize: this.page.size,
            status:1,
            order:"asc", 
            column:"realname" 
          };
          let { result } = await queryStaffList(params);
          
          let newArr = result.records.filter(item=>{
            return item.status == 1
          })
          if (params.pageNo == 1) {
            this.staffList = newArr;
          } else {
            this.staffList.push(...newArr);
          }
          this.page.end = this.staffList.length >= result.total;
          this.page.loading = false;
          this.page.current += 1;
        } catch (err) {
          this.page.loading = false;
        }
      },
      clickStaff(info){
        let index = this.map[info.id];
        if (index != undefined) {
          this.Data.splice(index, 1);
          // Reflect.deleteProperty(this.map, info.id);
          delete this.map[info.id]
        }else {
          this.map[info.id] = this.Data.length;
          this.Data.push({
            employeeId: info.id,
            employeeName: info.realname +(info.workNo?"["+info.workNo+"]":''),
            percent: '',
            amount: '',
            percentage:'',
            cardPrice: this.detail.orderPrice
          });
        }
        this.Data.forEach(item=>{
          item.amount = Number(item.cardPrice / this.Data.length).toFixed(2)
          item.percent = Number((item.amount/item.cardPrice)*100).toFixed(2)
          item.percentage = ((this.detail.commissionRate/100)*item.amount).toFixed(2)
        })
      },
      editNum(index) {
        this.curStaff = index;
        let info = this.Data[index];
        let that = this;

        let list = [
          { num: info.percent || 0, text: "业绩比例", key: "percent" },
          { num: info.amount || 0, text: "业绩", key: "amount" },
          { num: info.percentage || 0, text: "提成", key: "percentage" },
        ];
        list[0].func = function(val,index){
          let num = val ? Number(val) : 0;
          this.list[1].num = Number(info.cardPrice * (num/100)).toFixed(2);
          this.list[2].num = Number(this.list[1].num * (that.detail.commissionRate/100)).toFixed(2);
        }
        list[1].func = function(val,index){
          let num = val ? Number(val) : 0;
          this.list[0].num = parseFloat((num/info.cardPrice)*100).toFixed(2);
          this.list[2].num = Number(num*(that.detail.commissionRate/100)).toFixed(2)
        }
        
        this.$refs.keyboardDialog.open("业绩", list);
      },
      changeNum(list) {
        let info = this.Data[this.curStaff]
        for (let item of list) {
          info[item.key] = item.num;
        }
        this.$set(
          this.Data,
          this.curStaff,
          info
        );
      },
      close() {
        this.dialogVisible = false;
      },

			handleChecked(value) {
        let arr = [];
        if(value.length>this.Data.length){
          const obj = {
            name: value[value.length-1],
            lists: []
          }
          this.Data.push(obj)
        }else {
          value.forEach(item=>{
            this.Data.forEach(element=>{
              if(element.name == item){
                arr.push(element)
              }
            })
            
          })
          this.Data = arr
        }
        
        // this.addServe()
        
			},

      openCalculator(){
        this.$emit('openCalculator', '');
      },

      addServe(val){
        this.addIndex = val;
        this.$refs.addServeDialog.dialogVisible = true
      },

      selectServe(val){
        this.Data.forEach((item,index)=>{
          if(this.addIndex == index){
            item.lists.push({
              	title: val,
								achie:'98',
								commission:'100'
            })
          }
        })
      },

      remove(val){
        this.Data.forEach((item,index)=>{
          if(val == index){
            this.Data.splice(index,1)
          }
        })
      },

      delServe(i,val){
        this.Data.splice(i,1)
        delete this.map[val.employeeId]
        // this.Data.forEach(item=>{
        //   item.amount = Number(item.cardPrice / this.Data.length).toFixed(2)
        //   item.percent = Number((item.amount/item.cardPrice)*100).toFixed(2)
        //   item.percentage = (item.percent/100)*item.cardPrice
        // })
        this.Data.forEach(item=>{
          item.amount = Number(item.cardPrice / this.Data.length).toFixed(2)
          item.percent = Number((item.amount/item.cardPrice)*100).toFixed(2)
          item.percentage = ((this.detail.commissionRate/100)*item.amount).toFixed(2)
        })
      },

      async submit(){
        let params = {
          cardOrderId: this.detail.id,
          perfTable: this.Data
        }
        let res = await saveTableSubmitCardPerf(params);
        if(res.success){
          this.$message.success('保存业绩成功');
          this.dialogVisible = false;
          this.$emit('success','');
        }
      }

		},
	};
</script>
<style lang="scss" scoped>
	.service-main{
		color: #333333;
	}
	.tt-link{
		display: flex;
		align-items: center;
		margin-left: auto;
		.link-item{
			margin-left: 20px;
			cursor: pointer;
			.iconfont{
				margin-right: 5px;
			}
		}
	}
	.service-row{
		display: flex;
		align-items: center;
    justify-content: space-between;
    flex: 1;
		padding: 10px;
		span{
			display: inline-block;
		}
		.icon-del{
			color: #818A9C;
			margin-left: auto;
		}
		.row-number{
			margin: 0 3px;
		}
		.col{
			padding: 0 10px;
      flex-shrink: 0;
		}
		// .col:nth-child(1){
		// 	width: 150px;
		// }
		// .col:nth-child(2){
		// 	width: 150px;
		// }
		// .col:nth-child(3){
		// 	width: 150px;
		// }
	}
	.service-panel{
		width: 510px;
		height: 560px;
		border-radius: 10px;
		background: #FFFFFF;
		margin-left: auto;
		border: 0.5px solid #DDDDDD;
		overflow: hidden;
		overflow-y: auto;
    .service-card{
      margin-bottom: 5px;
    }
		.service-card-hd{
			height: 50px;
			background: #F8F9FA;
			padding: 0 20px;
			color: #333;
			display: flex;
			align-items: center;
			font-weight: 500;
		}
		.tt-left{
      display: flex;
      align-items: center;
      flex-shrink: 0;
			.iconfont{
				margin-right: 5px;
				color: #818A9C;
			}
		}
	}
	.service-aside{
    display: flex;
    flex-direction: column;
		width: 206px;
		height: 560px;
		border-radius: 10px;
		background: #FFFFFF;
		box-sizing: border-box;
		padding: 1px;
		border: 0.5px solid #DDDDDD;
		::v-deep .el-checkbox{
			height: 50px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #F2F3F5;;
		}
		::v-deep .el-checkbox__label{
			color: #333!important;
		}
		::v-deep .el-checkbox__inner{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 18px;
			height: 18px;
		}
		::v-deep  .el-checkbox__inner::after{
			left: 6px;
			top: 3px;
		}
		::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, 
		::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
		    background-color: #1661F7;
		    border-color: #1661F7;
		}
		.service-hd{
			height: 50px;
			border-radius: 10px 10px 0px 0px;
			background: #F8F9FA;
			padding: 0 20px;
			color: #333;
			display: flex;
			align-items: center;
			font-weight: 500;
		}
    .check-list{
      flex: 1;
      overflow-y: auto;
    }
	}
	.form-foot {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;
		.btn {
			width: 140px;
			height: 42px;
			margin: 0 15px;
			border-radius: 4px;
			font-size: 16px;
		}

		.el-button--primary {
			box-shadow: 0px 3px 8px 0px rgba(22, 97, 247, 0.3);
		}

		.el-button--default {
			border-radius: 4px;
			background: #FFFFFF;
			border: 1px solid #E7E7E7;
			box-shadow: 0px 3px 8px 0px rgba(237, 237, 237, 0.5);
		}
	}

	::v-deep .custom-dialog{
		border-radius: 5px;
		overflow: hidden;
	}
	::v-deep .custom-dialog .el-dialog__header {
		background-color: #F8F9FA;
		font-size: 16px;
		margin-bottom: 0;
		height: 54px;
		display: flex;
		align-items: center;
		color: #000000;
		padding: 0 20px 0 30px;
		font-weight: 500;
		border-radius: 5px;
	}
	
	::v-deep .custom-dialog .el-dialog__body {
		padding: 20px 29px;
		background-color: #f2f3f5;
	}


</style>