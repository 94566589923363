const TokenKey = 'Admin-Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

const TenantKey = 'Admin-Tenant'

export function getTenant(){
  return localStorage.getItem(TenantKey)
}

export function setTenant(val){
  return localStorage.setItem(TenantKey,val)
}

export function removeTenant() {
  return localStorage.removeItem(TenantKey)
}

const UserInfoKey = 'Admin-UserInfo'

export function getUserInfo(){
  let info = localStorage.getItem(UserInfoKey)
  return info ? JSON.parse(info) : {};
}

export function setUserInfo(val){
  return localStorage.setItem(UserInfoKey,JSON.stringify(val))
}

export function removeUserInfo() {
  return localStorage.removeItem(UserInfoKey)
}

export function setAuth(val){
  return localStorage.setItem('auth',JSON.stringify(val))
}

export function getAuth(){
  let info = localStorage.getItem('auth')
  return info ? JSON.parse(info) : [];
}

export function setAllAuth(val){
  return localStorage.setItem('allAuth',JSON.stringify(val))
}

export function getAllAuth(){
  let info = localStorage.getItem('allAuth')
  return info ? JSON.parse(info) : [];
}

/**
 * 判断是否有权限
 */
export function hasPerm(perm){

  let userAuth = getAuth();

  if(userAuth == null || userAuth == undefined){
    return false;
  }

  let item = userAuth.find(function (currentValue, currentIndex) {
    if (currentValue.action == perm){
      return true;
    }
  });
  
  console.log("permission find:", item);
  
  if(item != undefined ){
    return true;
  }

  return false;
}