<template>
	<div class="app-container">
		<div style="padding: 100px;">
			<el-button type="primary" @click="showDrawer1">单次:洗剪吹(680)</el-button>	
			<el-button type="primary" @click="showDrawer2">单次:洗剪吹(680)</el-button>	
			<el-button type="primary" @click="showDrawer3">结算</el-button>	
			<el-button type="primary" @click="showDrawer4">付款码</el-button>	
			<el-button type="primary" @click="showDrawer5">服务属性-美发</el-button>	
			<el-button type="primary" @click="showDrawer6">加服务</el-button>	
			<el-button type="primary" @click="showDrawer7">业绩</el-button>	
		</div>
		<addServeDialog ref="addServeDialog"></addServeDialog>
		<jsachieveDialog ref="jsachieveDialog"></jsachieveDialog>
		<jsnumberDialog ref="jsnumberDialog"></jsnumberDialog>
		<jssingleDialog ref="jssingleDialog"></jssingleDialog>
		<paymentDialog ref="paymentDialog"></paymentDialog>
		<paytypeDialog ref="paytypeDialog"></paytypeDialog>
		<serveDialog ref="serveDialog"></serveDialog>
	</div>
</template>

<script>
	import addServeDialog from '@/components/add-serve-dialog.vue'
	import jsachieveDialog from '@/components/js-achieve-dialog.vue'
	import jsnumberDialog from '@/components/js-number-dialog.vue'
	import jssingleDialog from '@/components/js-single-dialog.vue'
	import paymentDialog from '@/components/payment-dialog.vue'
	import paytypeDialog from '@/components/paytype-dialog.vue'
	import serveDialog from '@/components/serve-dialog.vue'
	export default {
		components:{
			addServeDialog,
			jsachieveDialog,
			jsnumberDialog,
			jssingleDialog,
			paymentDialog,
			paytypeDialog,
			serveDialog,
		},
		data() {
			return {
			};
		},
		methods: {
			showDrawer1() {
				this.$refs.jssingleDialog.dialogVisible = true
			},
			showDrawer2() {
				this.$refs.jsnumberDialog.dialogVisible = true
			},
			showDrawer3() {
				this.$refs.paytypeDialog.dialogVisible = true
			},
			showDrawer4() {
				this.$refs.paymentDialog.dialogVisible = true
			},
			showDrawer5() {
				this.$refs.serveDialog.dialogVisible = true
			},
			showDrawer6() {
				this.$refs.addServeDialog.dialogVisible = true
			},
			showDrawer7() {
				this.$refs.jsachieveDialog.dialogVisible = true
			},
		},
	};
</script>
<style lang="scss" scoped>
	
</style>