<template>
	<el-dialog title="结算" top="10vh" :visible.sync="dialogVisible" width="425px" custom-class="custom-dialog">
		<div class="payment">
			<div class="pay-head">
				<div class="pay-title">请选择付款方式</div>
				<div class="pay-desc">本单还需要支付198元，请选择以下付款方式完成支付</div>
			</div>
			<div class="type-list">
				<div
				class="payment-item"
				  v-for="(item, index) in payList"
				  :key="index"
				  :class="{ item: true, on: index == curTab }"
				  @click="changeTab(item,index)"
				>
				  <div class="iconfont" :class="item.icon"></div>
				  <div class="flex-1">{{ item.name }}</div>
				  <img class="radio" :src="index == curTab?radioed:radio" alt="" />
				</div>
			</div>
			<div class="form-foot">
				<el-button class="btn" type="danger" @click="cancel">取消</el-button>
				<el-button class="btn" type="primary" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				radio:require('@/assets/radio.png'),
				radioed:require('@/assets/radioed.png'),
				payList: [
					{
						icon: "icon-scan",
						name: "扫码付款",
            value: "pay_online"
					},{
						icon: "icon-wxpay",
						name: "个人微信",
            value: "pay_online"
					},{
						icon: "icon-alipay",
						name: "个人支付宝",
            value: "pay_online"
					},{
						icon: "icon-xianjinzhifu",
						name: "现金",
            value: "pay_cash"
					},{
						icon: "icon-crash",
						name: "POS支付",
            value: "pay_pos"
					},
				],
				curTab: 0,
        payWay: 'pay_online'
			};
		},
		methods: {
		  changeTab(val,i){
			  this.curTab = i;
        this.payWay = val.value
		  },

      cancel(){
        this.dialogVisible = false;
      },

      confirm(){
        this.$emit('pay', this.payWay);
      }
		},
	};
</script>
<style lang="scss" scoped>
	.payment{
		padding: 18px 18px 0 18px;
		border-radius: 10px;
		background: #FFFFFF;
		box-sizing: border-box;
		border: 0.5px solid #DDDDDD;
		.payment-item{
			display: flex;
			align-items: center;
			padding: 20px 5px;
			cursor: pointer;
			line-height: 1;
			border-bottom: 1px solid #F2F3F5;
			.flex-1{
				font-weight: bold;
				font-size: 18px;
			}
			.iconfont{
				font-size: 22px;
				margin-right: 10px;
			}
			.icon-scan{
				color: #FF992D;
			}
			.icon-wxpay{
				color: #00C800;
			}
			.icon-alipay{
				color: #00A0E9;
			}
			.icon-xianjinzhifu{
				color: #1661F7;
			}
			.icon-crash{
				color: #28A7F3;
			}
		}
		.pay-head{
			text-align: center;
			margin-bottom: 10px;
			.pay-title{
				font-weight: 500;
				font-size: 16px;
				color: #000000;
				margin-bottom: 5px;
			}
			.pay-desc{
				font-size: 13px;
				opacity: 0.99;
			}
		}
	}
	.form-foot {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px 0;

		.btn {
			width: 100px;
			height: 42px;
			margin: 0 15px;
			border-radius: 4px;
			font-size: 16px;
		}

		.el-button--primary {
			box-shadow: 0px 3px 8px 0px rgba(22, 97, 247, 0.3);
		}

		.el-button--default {
			border-radius: 4px;
			background: #FFFFFF;
			border: 1px solid #E7E7E7;
			box-shadow: 0px 3px 8px 0px rgba(237, 237, 237, 0.5);
		}
	}

	::v-deep .custom-dialog{
		border-radius: 5px;
		overflow: hidden;
	}
	::v-deep .custom-dialog .el-dialog__header {
		background-color: #F8F9FA;
		font-size: 16px;
		margin-bottom: 0;
		height: 54px;
		display: flex;
		align-items: center;
		color: #000000;
		padding: 0 20px 0 30px;
		font-weight: 500;
		border-radius: 5px;
	}
	
	::v-deep .custom-dialog .el-dialog__body {
		padding: 20px 29px;
		background-color: #f2f3f5;
	}


</style>