import router from './router'
import { getToken, hasPerm } from '@/utils/auth' // get token from cookie
import { Message } from 'element-ui';

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // determine whether the user has logged in
  const hasToken = getToken()
  // next();
  if (!hasToken) {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  } else {
    console.log("router.to", to);
    let perm = to.meta.permission;
    if(perm && perm != ""){
      if(hasPerm(perm)){
        next();
      }else{
        // next(`/login?redirect=${to.path}`);
        console.log("无权限", perm);
        Message({
          message: '没有本功能操作权限，联系门店管理员或总帐号授权',
          type: 'warning'
        });
      }
    }else{
      next();
    }
  }
  
})
