<template>
  <div class="app-wrapper">
    <sidebar class="sidebar-container" />
    <div  class="main-container">
      <app-main />
    </div>
  </div>
</template>

<script>

import { AppMain, Sidebar } from "./components";
import { mapState } from "vuex";
import { listAllPermissionByToken } from "@/api";
import store from '@/store'

export default {
  name: "Layout",
  components: {
    AppMain,
    Sidebar,
  },
  mixins: [],
  computed: {
    ...mapState({
     
    }),
  },
  mounted() { 
    this.loadAuth();
  },
  methods: {
    async loadAuth(){
      console.log("loadAuth...", this.$store);
      let token = store.getters.token;
      console.log("state.token", token);
      let ret = await listAllPermissionByToken({token: token});
      if(ret.success){
        store.commit("user/SET_AUTH", ret.result.auth);
        store.commit("user/SET_ALL_AUTH", ret.result.allAuth);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background: linear-gradient(179deg, #4475FF 5%, #9551FF 92%);
  display: flex;
  .sidebar-container {
    width: 140px;
  }
  .main-container {
    flex: 1;
    border-radius: 40px 0px 0px 40px;
    background: #F2F3F5;
    overflow: hidden;
  }
}
</style>
